export const CHAIN_INFO = {
  chainId: 'AELF',
  exploreUrl: 'https://aelfscan.io/AELF/',
  exploreDomain: 'https://aelfscan.io/',
  rpcUrl: 'https://aelf-public-node.aelf.io',
  // rpcUrl: 'https://aelfscan.io/chain',
};

export const TOKEN_CONTRACT = 'JRmBduh4nXWi1aXgdUsj5gJrzeZb2LxmrAbf7W99faZSvoAaE';
export const ETRANSFER_CONTRACT = '2w13DqbuuiadvaSY2ZyKi2UoXg354zfHLM3kwRKKy85cViw4ZF';
export const CA_CONTRACT = '28PcLvP41ouUd6UNGsNRvKpkFTe6am34nPy4YPsWUJnZNwUvzM';

export const CA_CONTRACT_V2 = '2UthYi7AHRdfrqc1YCfeQnjdChDLaas65bW4WxESMGMojFiXj9';
